// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { ThemeProvider } from './_Hooks/_Context/ThemeContext';
import { UseDataUserProvider } from './_Hooks/_Context/useDataUserAuthenticated';
import { NotificationsProvider } from 'reapop';
import { pdfjs } from 'react-pdf'; // Importa pdfjs

import './_Assets/scss/volt.scss';
import './_Assets/App.css';
import 'react-month-picker/css/month-picker.css';
import 'react-tooltip/dist/react-tooltip.css';
import './variables.css';

// Configurar workerSrc para usar el CDN de PDF.js que coincide con la versión instalada
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <NotificationsProvider>
      <UseDataUserProvider>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </UseDataUserProvider>
    </NotificationsProvider>
  </React.StrictMode>
);