import { useState, useEffect } from "react";

const WeatherComponent = () => {
    const [temperature, setTemperature] = useState(null);
    const [location, setLocation] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchWeather = async (latitude, longitude) => {
            try {
                const response = await fetch(
                    `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current_weather=true`
                );
                const data = await response.json();
        
                // Verifica si el campo current_weather está presente
                if (data.current_weather) {
                    setTemperature(data.current_weather.temperature);
                } else {
                    console.error("No se encontró información del clima en la respuesta.");
                    setError("No se pudo obtener la información del clima.");
                }
            } catch (error) {
                console.error("Error al obtener el clima:", error);
                setError("No se pudo obtener el clima.");
            }
        };

        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setLocation({ latitude, longitude });
                        fetchWeather(latitude, longitude);
                    },
                    (err) => {
                        console.error("Error al obtener la ubicación:", err);
                        setError("No se pudo obtener la ubicación.");
                    }
                );
            } else {
                setError("La geolocalización no está soportada por tu navegador.");
            }
        };

        getLocation();
    }, []);

    return (
        <div>
            {error ? (
                <p>{error}</p>
            ) : temperature !== null ? (
                <p>
                     {temperature}°C
                </p>
            ) : (
                <p>Cargando datos del clima...</p>
            )}
          
                
            
        </div>
    );
};
export default WeatherComponent;