// src/_API/axiosConfig.js
import axios from "axios";

// Establece la URL base desde variables de entorno o usa la predeterminada
const baseURL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000';

// Configura Axios con la URL base
axios.defaults.baseURL = baseURL;

// **Elimina o comenta esta línea para evitar conflictos de Content-Type**
// axios.defaults.headers.post['Content-Type'] = 'application/json';

// Opcional: Configura otros defaults si es necesario

export default axios;