// src/_Hooks/_Context/ThemeContext.js

import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode') || 'light');

    useEffect(() => {
        document.body.classList.remove('theme-light', 'theme-dark'); // Remover clases previas
        document.body.classList.add(`theme-${themeMode}`); // Agregar clase actual
        localStorage.setItem('themeMode', themeMode); // Guarda el tema en localStorage
    }, [themeMode]);

    const toggleTheme = () => {
        setThemeMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
    };

    return (
        <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};